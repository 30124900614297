@tailwind base;
@tailwind components;
@tailwind utilities;


.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused,
.ck.ck-editor__editable_inline {
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
}

.flex.space-x-2:empty {
    display: none;
}


.pg-viewer-wrapper {
    overflow-y: unset !important;
}

.photo-viewer-container {
    width: unset !important;
    height: unset !important;
}

.photo-viewer-container>img {
    width: unset !important;
    height: unset !important;
}